@import "@uik/_vars.scss";

.aboutAppContainer {
  flex-basis: 130px;
  flex-shrink: 0;
  padding: 25px;
  border-top: 1px solid $borderColor;
  a {
    all: inherit;
    cursor: pointer;
    text-decoration: underline;
  }
  span:not([role="img"]):after {
    content: '\A';
    white-space: pre;
  }
  .copyright {
    font-size: 0.875rem;
    line-height: 1.375rem;
    margin-bottom: 8px;
    color: $textSecondary;
  }
  .copyAbout {
    font-size: 0.75rem;
    color: $textSecondary;
    line-height: 1.5rem;
  }
}
