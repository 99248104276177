@import '@uik/_vars.scss';

.page {}

.selectRange {
  @media screen and (max-width: 450px) {
    display: none;
  }
}

.widgetOverflow {
  max-width: calc(100% - 30px)
}

.boxes {
  display: flex;
  flex-wrap: wrap;
  &>* {
    min-width: 1px;
    flex-basis: 100%;
    border-bottom: $borderDefault;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media screen and (min-width: 600px) {
    &>* {
      flex-basis: 50%;
      &:nth-child(2n+1) {
        border-right: $borderDefault;
      }
    }
  }
  @media screen and (min-width: 1300px) {
    &>* {
      flex-basis: 50%;
      &:not(:last-child) {
        border-right: $borderDefault;
      }
    }
  }
}

.box {
  padding: 25px 30px;
}

.boxContent {
  display: flex;
  align-items: center;
  &>*:not(:last-child) {
    margin-right: 15px;
  }
}

.boxValue {
  font-size: 1.875rem;
  line-height: 2.75rem;
  color: $textMain;
  &.boxValuePending {
    color: $textSecondary;
  }
}

.tables {
  display: flex;
  flex-wrap: wrap;
  &>* {
    flex-basis: calc(100%);
  }
  @media screen and (min-width: 1300px) {
    &>* {
      flex-basis: calc(50% - 30px);
    }
  }
}

.tableWrapper {
  overflow-x: auto;
  width: 100%;
}

table.tableProfit,
table.tableInvoices {
  width: 100%;
  border: none;
  td {
    padding: 1rem 15px 1rem 15px;
    font-size: .875rem;
  }
  td:last-child {
    padding: 1rem 30px 1rem 15px;
    text-align: right;
  }
  td:first-child {
    padding: 1rem 15px 1rem 30px;
  }
  tr {
    &:not(:last-child) {
      td {
        border-bottom: $borderDefault;
      }
    }
  }
}

table.tableInvoices {
  width: 100%;
  border: none;
  td,
  td:last-child,
  td:first-child {
    padding-top: 9px;
    padding-bottom: 9px;
    font-size: .875rem;
    line-height: 1.375rem;
  }
  .name,
  .value {
    color: $textMain;
  }
  .invoiceId {
    color: $textSecondary;
  }
  .value {
    text-align: right;
    position: relative;
    &.payed,
    &.unpayed {
      padding-right: 44px;
      &:after {
        content: '';
        display: block;
        position: absolute;
        height: 6px;
        width: 6px;
        border-radius: 50%;
        background: $red;
        top: 50%;
        transform: translateY(-50%);
        right: 30px;
      }
    }
    &.payed {
      &:after {
        background: $green;
      }
    }
  }
}
