.uik-nav-link-two-container__wrapper {
  padding: 16px 30px 30px; }
.uik-divider__horizontal {
  height: 1px;
  width: 100%;
  background: #EAEDF3; }
  .uik-divider__horizontal.uik-divider__margin {
    margin-top: 24px;
    margin-bottom: 24px; }

.uik-divider__vertical {
  width: 1px;
  height: 100%;
  background: #EAEDF3; }
  .uik-divider__vertical.uik-divider__margin {
    margin-left: 24px;
    margin-right: 24px; }
.uik-avatar__info {
  margin-left: 12px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }
  .uik-avatar__info > * {
    display: block; }

.uik-avatar__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 100%; }
  .uik-avatar__wrapper > * {
    min-width: 1px; }
  .uik-avatar__wrapper .uik-avatar__name {
    /* Author: */
    font-size: 0.875rem;
    color: #3E3F42;
    line-height: 1.375rem;
    overflow: hidden;
    white-space: nowrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .uik-avatar__wrapper .uik-avatar__name i {
      font-style: normal;
      color: #9EA0A5; }
  .uik-avatar__wrapper.uik-avatar__highlighted .uik-avatar__name {
    font-weight: 500; }
  .uik-avatar__wrapper .uik-avatar__avatarWrapper {
    position: relative;
    height: 38px;
    width: 38px;
    -ms-flex-negative: 0;
        flex-shrink: 0; }
    .uik-avatar__wrapper .uik-avatar__avatarWrapper .uik-avatar__avatarAction {
      position: absolute;
      bottom: 0;
      right: 0; }
    .uik-avatar__wrapper .uik-avatar__avatarWrapper .uik-avatar__avatarPlaceholder {
      height: 100%;
      width: 100%;
      border-radius: 50%;
      color: #9EA0A5;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      font-weight: 500;
      line-height: 1.5rem;
      text-transform: uppercase;
      background-color: transparent;
      border: 1px solid #EAEDF3; }
      .uik-avatar__wrapper .uik-avatar__avatarWrapper .uik-avatar__avatarPlaceholder.green {
        color: white;
        background-color: #38B249;
        border-color: transparent; }
      .uik-avatar__wrapper .uik-avatar__avatarWrapper .uik-avatar__avatarPlaceholder.blue {
        color: white;
        background-color: #1665D8;
        border-color: transparent; }
      .uik-avatar__wrapper .uik-avatar__avatarWrapper .uik-avatar__avatarPlaceholder.orange {
        color: white;
        background-color: #F6AB2F;
        border-color: transparent; }
      .uik-avatar__wrapper .uik-avatar__avatarWrapper .uik-avatar__avatarPlaceholder.violet {
        color: white;
        background-color: #6977FF;
        border-color: transparent; }
      .uik-avatar__wrapper .uik-avatar__avatarWrapper .uik-avatar__avatarPlaceholder.yellow {
        color: white;
        background-color: #FACF55;
        border-color: transparent; }
      .uik-avatar__wrapper .uik-avatar__avatarWrapper .uik-avatar__avatarPlaceholder.red {
        color: white;
        background-color: #E6492D;
        border-color: transparent; }
      .uik-avatar__wrapper .uik-avatar__avatarWrapper .uik-avatar__avatarPlaceholder.lightblue {
        background-color: #98CAEC;
        background-image: -webkit-linear-gradient(135deg, #98CAEC 0%, #44ADE5 100%);
        background-image: -o-linear-gradient(135deg, #98CAEC 0%, #44ADE5 100%);
        background-image: linear-gradient(-45deg, #98CAEC 0%, #44ADE5 100%);
        border-color: transparent;
        color: white; }
    .uik-avatar__wrapper .uik-avatar__avatarWrapper .uik-avatar__avatar {
      height: 100%;
      width: 100%;
      border-radius: 50%;
      display: block; }
      .uik-avatar__wrapper .uik-avatar__avatarWrapper .uik-avatar__avatar + * {
        margin-left: 0.75rem; }
    .uik-avatar__wrapper .uik-avatar__avatarWrapper .uik-avatar__avatarMultiWrapper {
      height: 100%;
      width: 100%;
      border-radius: 50%;
      overflow: hidden;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .uik-avatar__wrapper .uik-avatar__avatarWrapper .uik-avatar__avatarMultiWrapper > .uik-avatar__avatar {
        border-radius: 0;
        margin: 0 !important; }
      .uik-avatar__wrapper .uik-avatar__avatarWrapper .uik-avatar__avatarMultiWrapper.uik-avatar__s2 > .uik-avatar__avatar {
        -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
                transform: translateX(-50%); }
      .uik-avatar__wrapper .uik-avatar__avatarWrapper .uik-avatar__avatarMultiWrapper.uik-avatar__s3 > .uik-avatar__avatar:nth-child(1) {
        -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
                transform: translateX(-50%); }
      .uik-avatar__wrapper .uik-avatar__avatarWrapper .uik-avatar__avatarMultiWrapper.uik-avatar__s3 > .uik-avatar__avatar:nth-child(2), .uik-avatar__wrapper .uik-avatar__avatarWrapper .uik-avatar__avatarMultiWrapper.uik-avatar__s3 > .uik-avatar__avatar:nth-child(3) {
        height: 50%;
        width: 50%; }
      .uik-avatar__wrapper .uik-avatar__avatarWrapper .uik-avatar__avatarMultiWrapper.uik-avatar__s3 > .uik-avatar__avatar:nth-child(2) {
        -webkit-transform: translateX(-100%);
            -ms-transform: translateX(-100%);
                transform: translateX(-100%); }
      .uik-avatar__wrapper .uik-avatar__avatarWrapper .uik-avatar__avatarMultiWrapper.uik-avatar__s3 > .uik-avatar__avatar:nth-child(3) {
        -webkit-transform: translateX(-200%) translateY(100%);
            -ms-transform: translateX(-200%) translateY(100%);
                transform: translateX(-200%) translateY(100%); }
      .uik-avatar__wrapper .uik-avatar__avatarWrapper .uik-avatar__avatarMultiWrapper.uik-avatar__s4 {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
        .uik-avatar__wrapper .uik-avatar__avatarWrapper .uik-avatar__avatarMultiWrapper.uik-avatar__s4 > .uik-avatar__avatar {
          height: 50%;
          width: 50%; }
  .uik-avatar__wrapper .uik-avatar__textTop {
    font-size: 0.75rem;
    color: #9EA0A5;
    line-height: 1.125rem;
    overflow: hidden;
    white-space: nowrap; }
  .uik-avatar__wrapper .uik-avatar__textBottom {
    font-size: 0.75rem;
    color: #9EA0A5;
    line-height: 1.125rem;
    margin-top: 2px;
    overflow: hidden;
    white-space: nowrap; }
  .uik-avatar__wrapper .uik-avatar__textTop,
  .uik-avatar__wrapper .uik-avatar__textBottom,
  .uik-avatar__wrapper .uik-avatar__name {
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis; }
  .uik-avatar__wrapper.uik-avatar__small .uik-avatar__avatarWrapper {
    font-size: 12px;
    height: 28px;
    width: 28px; }
  .uik-avatar__wrapper.uik-avatar__large .uik-avatar__avatarWrapper, .uik-avatar__wrapper.uik-avatar__larger .uik-avatar__avatarWrapper, .uik-avatar__wrapper.uik-avatar__jumbo .uik-avatar__avatarWrapper, .uik-avatar__wrapper.uik-avatar__extraLarge .uik-avatar__avatarWrapper {
    font-size: 18px;
    height: 60px;
    width: 60px; }
  .uik-avatar__wrapper.uik-avatar__large .uik-avatar__textTop,
  .uik-avatar__wrapper.uik-avatar__large .uik-avatar__textBottom, .uik-avatar__wrapper.uik-avatar__larger .uik-avatar__textTop,
  .uik-avatar__wrapper.uik-avatar__larger .uik-avatar__textBottom, .uik-avatar__wrapper.uik-avatar__jumbo .uik-avatar__textTop,
  .uik-avatar__wrapper.uik-avatar__jumbo .uik-avatar__textBottom, .uik-avatar__wrapper.uik-avatar__extraLarge .uik-avatar__textTop,
  .uik-avatar__wrapper.uik-avatar__extraLarge .uik-avatar__textBottom {
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: #6B6C6F; }
  .uik-avatar__wrapper.uik-avatar__large .uik-avatar__name, .uik-avatar__wrapper.uik-avatar__larger .uik-avatar__name, .uik-avatar__wrapper.uik-avatar__jumbo .uik-avatar__name, .uik-avatar__wrapper.uik-avatar__extraLarge .uik-avatar__name {
    font-size: 1rem;
    line-height: 1.5rem; }
  .uik-avatar__wrapper.uik-avatar__larger .uik-avatar__textTop,
  .uik-avatar__wrapper.uik-avatar__larger .uik-avatar__textBottom {
    color: #6B6C6F; }
  .uik-avatar__wrapper.uik-avatar__larger .uik-avatar__avatarWrapper {
    font-size: 26px;
    height: 80px;
    width: 80px; }
  .uik-avatar__wrapper.uik-avatar__extraLarge .uik-avatar__avatarWrapper {
    font-size: 36px;
    height: 100px;
    width: 100px; }
  .uik-avatar__wrapper.uik-avatar__jumbo .uik-avatar__name {
    font-size: 1.25rem;
    line-height: 1.5; }
  .uik-avatar__wrapper.uik-avatar__jumbo .uik-avatar__avatarWrapper {
    font-size: 36px;
    height: 120px;
    width: 120px; }
  .uik-avatar__wrapper.uik-avatar__jumbo .uik-avatar__info {
    margin-left: 24px; }

.uik-avatar__statusWrapper {
  margin-left: 10px;
  text-align: right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #9EA0A5;
  font-size: .75rem; }
  .uik-avatar__statusWrapper .uik-avatar__statusOnline {
    background: #38B249;
    height: 6px;
    width: 6px;
    border-radius: 50%; }
.uik-tag__wrapper {
  border-radius: 4px;
  /* ADMIN: */
  font-size: 0.75rem;
  text-transform: uppercase;
  text-align: center;
  line-height: 18px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid #EAEDF3;
  color: #9EA0A5;
  font-weight: 500;
  padding: 0 7px;
  height: 24px; }
  .uik-tag__wrapper:not(.uik-tag__fill).green {
    border: 1px solid #38B249;
    color: #38B249; }
  .uik-tag__wrapper:not(.uik-tag__fill).blue {
    border: 1px solid #1665D8;
    color: #1665D8; }
  .uik-tag__wrapper:not(.uik-tag__fill).orange {
    border: 1px solid #F6AB2F;
    color: #F6AB2F; }
  .uik-tag__wrapper:not(.uik-tag__fill).violet {
    border: 1px solid #6977FF;
    color: #6977FF; }
  .uik-tag__wrapper:not(.uik-tag__fill).yellow {
    border: 1px solid #FACF55;
    color: #FACF55; }
  .uik-tag__wrapper:not(.uik-tag__fill).red {
    border: 1px solid #E6492D;
    color: #E6492D; }
  .uik-tag__wrapper:not(.uik-tag__fill).white {
    border: 1px solid white;
    color: white; }
  .uik-tag__wrapper.uik-tag__fill.green {
    background: #38B249;
    border: 1px solid #38B249;
    color: white; }
  .uik-tag__wrapper.uik-tag__fill.blue {
    background: #1665D8;
    border: 1px solid #1665D8;
    color: white; }
  .uik-tag__wrapper.uik-tag__fill.orange {
    background: #F6AB2F;
    border: 1px solid #F6AB2F;
    color: white; }
  .uik-tag__wrapper.uik-tag__fill.violet {
    background: #6977FF;
    border: 1px solid #6977FF;
    color: white; }
  .uik-tag__wrapper.uik-tag__fill.yellow {
    background: #FACF55;
    border: 1px solid #FACF55;
    color: white; }
  .uik-tag__wrapper.uik-tag__fill.red {
    background: #E6492D;
    border: 1px solid #E6492D;
    color: white; }
  .uik-tag__wrapper.uik-tag__fill.white {
    background: white;
    color: black; }
.uik-tag-container__wrapper {
  margin: -3px; }
  .uik-tag-container__wrapper > * {
    margin: 3px; }
.uik-progress-bar__wrapper, .uik-progress-bar__progressLine {
  width: 100%;
  height: 4px;
  background: #EAECEE;
  border-radius: 4px; }

.uik-progress-bar__progressLine {
  background: #1665D8; }
.uik-status-dot__wrapper {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #38B249;
  display: inline-block;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0; }
  .uik-status-dot__wrapper.green {
    background: #38B249; }
  .uik-status-dot__wrapper.blue {
    background: #1665D8; }
  .uik-status-dot__wrapper.orange {
    background: #F6AB2F; }
  .uik-status-dot__wrapper.violet {
    background: #6977FF; }
  .uik-status-dot__wrapper.yellow {
    background: #FACF55; }
  .uik-status-dot__wrapper.red {
    background: #E6492D; }
  .uik-status-dot__wrapper.grey {
    background: #cad1e0; }
.uik-nav-icon__wrapper {
  height: 20px;
  width: 20px;
  position: relative;
  color: #9EA0A5; }
  .uik-nav-icon__wrapper .uik-nav-icon__a,
  .uik-nav-icon__wrapper .uik-nav-icon__b,
  .uik-nav-icon__wrapper .uik-nav-icon__c {
    position: absolute;
    fill: currentColor;
    -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
            transform: rotate(0);
    -webkit-transition: .2s top .4s, .1s left .2s, .2s transform, .2s opacity .2s;
    -o-transition: .2s top .4s, .1s left .2s, .2s transform, .2s opacity .2s;
    transition: .2s top .4s, .1s left .2s, .2s transform, .2s opacity .2s; }
  .uik-nav-icon__wrapper .uik-nav-icon__a {
    left: 0;
    top: 2px; }
  .uik-nav-icon__wrapper .uik-nav-icon__b {
    top: 9px;
    left: 0; }
  .uik-nav-icon__wrapper .uik-nav-icon__c {
    left: 6px;
    top: 16px; }
  .uik-nav-icon__wrapper.uik-nav-icon__isOpen .uik-nav-icon__a,
  .uik-nav-icon__wrapper.uik-nav-icon__isOpen .uik-nav-icon__b,
  .uik-nav-icon__wrapper.uik-nav-icon__isOpen .uik-nav-icon__c {
    -webkit-transition: .2s opacity, .2s top, .2s left .2s, .2s transform .4s;
    -o-transition: .2s opacity, .2s top, .2s left .2s, .2s transform .4s;
    transition: .2s opacity, .2s top, .2s left .2s, .2s transform .4s; }
  .uik-nav-icon__wrapper.uik-nav-icon__isOpen .uik-nav-icon__a,
  .uik-nav-icon__wrapper.uik-nav-icon__isOpen .uik-nav-icon__c {
    right: auto;
    left: 3px;
    top: 9px; }
  .uik-nav-icon__wrapper.uik-nav-icon__isOpen .uik-nav-icon__b {
    opacity: 0; }
  .uik-nav-icon__wrapper.uik-nav-icon__isOpen .uik-nav-icon__a {
    -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .uik-nav-icon__wrapper.uik-nav-icon__isOpen .uik-nav-icon__c {
    -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
            transform: rotate(45deg); }
.uik-messages__bubble {
  margin-bottom: 1px;
  width: auto;
  border-radius: 10px;
  padding: 18px 24px;
  font-size: .875rem;
  line-height: 1.5;
  border: 1px solid #EAEDF3; }
  .uik-messages__bubble.uik-messages__right {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background: white; }
  .uik-messages__bubble.uik-messages__left {
    background: #1665D8;
    color: white;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; }
  .uik-messages__bubble:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; }
.uik-messages__container {
  max-width: 760px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .uik-messages__container.uik-messages__right {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    margin-right: 0;
    margin-left: auto;
    padding-left: 50px; }
  .uik-messages__container.uik-messages__left {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    margin-left: 0;
    margin-right: auto;
    padding-right: 50px; }
  .uik-messages__container:not(:first-child) {
    margin-top: 30px; }
.uik-messages__sender {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 12px; }

.uik-messages__timeSent {
  font-size: .75rem;
  margin-left: 15px; }
.uik-messages__image {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 80px;
  width: 80px;
  border-radius: 4px; }
.uik-messages__imageContainer {
  margin-left: -5px;
  margin-right: -5px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .uik-messages__imageContainer > * {
    margin: 5px; }
.uik-loader-dots__dotloader {
  display: inline-block;
  width: 70px;
  text-align: center; }
  .uik-loader-dots__dotloader > div {
    width: 7px;
    height: 7px;
    background-color: white;
    border-radius: 100%;
    vertical-align: middle;
    display: inline-block;
    -webkit-animation: uik-loader-dots__bouncedelay 1s infinite ease-in-out both;
            animation: uik-loader-dots__bouncedelay 1s infinite ease-in-out both; }
  .uik-loader-dots__dotloader .uik-loader-dots__dotloader1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
    margin-right: 2px; }
  .uik-loader-dots__dotloader .uik-loader-dots__dotloader2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
    margin-right: 2px; }

@-webkit-keyframes uik-loader-dots__bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes uik-loader-dots__bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }
.uik-btn-group__wrapper {
  display: inline-block; }
  .uik-btn-group__wrapper > * {
    border-radius: 0; }
    .uik-btn-group__wrapper > *:first-child {
      border-radius: 4px 0 0 4px; }
    .uik-btn-group__wrapper > *:last-child {
      border-radius: 0 4px 4px 0; }
    .uik-btn-group__wrapper > *:not(:last-child) {
      border-right-color: transparent; }
a.uik-btn__base:hover {
  text-decoration: none !important; }

.uik-btn__base {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: 38px;
  padding: 0 18px;
  -webkit-transition: all 0.1s ease-in-out 0s;
  -o-transition: all 0.1s ease-in-out 0s;
  transition: all 0.1s ease-in-out 0s;
  font-size: 0.875rem;
  font-weight: 400;
  position: relative;
  /*Basic Appearence*/
  background: #FFFFFF;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#F6F7F9), to(#FFFFFF));
  background-image: -webkit-linear-gradient(bottom, #F6F7F9 0%, #FFFFFF 100%);
  background-image: -o-linear-gradient(bottom, #F6F7F9 0%, #FFFFFF 100%);
  background-image: linear-gradient(0deg, #F6F7F9 0%, #FFFFFF 100%);
  border: 1px solid #D8DCE6;
  -webkit-box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.05), inset 0 2px 0 0 rgba(255, 255, 255, 0.05);
          box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.05), inset 0 2px 0 0 rgba(255, 255, 255, 0.05);
  color: #3E3F42;
  border-radius: 4px;
  /*Colors*/ }
  .uik-btn__base i {
    opacity: .8; }
  .uik-btn__base.uik-btn__hasIcon {
    padding-left: 40px; }
    .uik-btn__base.uik-btn__hasIcon .uik-btn__iconWrapper {
      position: absolute;
      top: 50%;
      left: 12px;
      -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%);
      font-size: 18px; }
  .uik-btn__base.uik-btn__iconRight {
    padding-right: 40px;
    padding-left: 18px; }
    .uik-btn__base.uik-btn__iconRight .uik-btn__iconWrapper {
      left: auto;
      right: 12px; }
  .uik-btn__base.uik-btn__iconOnly {
    padding: 0;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -ms-flex-preferred-size: 38px;
        flex-basis: 38px;
    width: 38px;
    -ms-flex-negative: 0;
        flex-shrink: 0; }
    .uik-btn__base.uik-btn__iconOnly .uik-btn__iconWrapper {
      left: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%);
          -ms-transform: translateX(-50%) translateY(-50%);
              transform: translateX(-50%) translateY(-50%); }
  .uik-btn__base.uik-btn__isExpanded {
    width: 100%; }
    .uik-btn__base.uik-btn__isExpanded.uik-btn__hasIcon {
      padding-left: 40px;
      padding-right: 40px; }
  .uik-btn__base .uik-btn__content {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .uik-btn__base > * {
    -webkit-transition: .1s all;
    -o-transition: .1s all;
    transition: .1s all; }
  .uik-btn__base > .uik-btn__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .uik-btn__base.uik-btn__isLoading > * {
    opacity: 0; }
  .uik-btn__base.uik-btn__isLoading > .uik-btn__loader {
    opacity: 1; }
  .uik-btn__base.uik-btn__xs {
    height: 28px;
    padding: 0 15px;
    font-size: 12px; }
  .uik-btn__base.uik-btn__lg {
    height: 48px;
    padding: 0 40px;
    font-size: 16px; }
  .uik-btn__base:not(:disabled) {
    cursor: pointer; }
  .uik-btn__base:focus {
    outline: none; }
  .uik-btn__base:active {
    border: 1px solid #eee;
    -webkit-box-shadow: none;
            box-shadow: none;
    -webkit-transform: translateY(0px);
        -ms-transform: translateY(0px);
            transform: translateY(0px); }
  .uik-btn__base:hover {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#F6F7F9), to(#FFFFFF));
    background-image: -webkit-linear-gradient(bottom, #F6F7F9 0%, #FFFFFF 100%);
    background-image: -o-linear-gradient(bottom, #F6F7F9 0%, #FFFFFF 100%);
    background-image: linear-gradient(0deg, #F6F7F9 0%, #FFFFFF 100%);
    border: 1px solid #CACEDB;
    -webkit-box-shadow: 0 1px 2px 0 rgba(22, 29, 37, 0.1), inset 0 2px 0 0 rgba(255, 255, 255, 0.05);
            box-shadow: 0 1px 2px 0 rgba(22, 29, 37, 0.1), inset 0 2px 0 0 rgba(255, 255, 255, 0.05); }
  .uik-btn__base:focus {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#F6F7F9), to(#FFFFFF));
    background-image: -webkit-linear-gradient(bottom, #F6F7F9 0%, #FFFFFF 100%);
    background-image: -o-linear-gradient(bottom, #F6F7F9 0%, #FFFFFF 100%);
    background-image: linear-gradient(0deg, #F6F7F9 0%, #FFFFFF 100%);
    border: 1px solid #BDC2D1;
    -webkit-box-shadow: 0 0 1px 0 rgba(22, 29, 37, 0.1), inset 0 2px 0 0 rgba(255, 255, 255, 0.05);
            box-shadow: 0 0 1px 0 rgba(22, 29, 37, 0.1), inset 0 2px 0 0 rgba(255, 255, 255, 0.05); }
  .uik-btn__base.uik-btn__noBorder {
    border: none !important;
    background: white;
    -webkit-box-shadow: none;
            box-shadow: none; }
    .uik-btn__base.uik-btn__noBorder:hover {
      -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.13);
              box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.13); }
    .uik-btn__base.uik-btn__noBorder:focus {
      -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
              box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1); }
  .uik-btn__base.uik-btn__transparent {
    background: transparent;
    border: 1px solid #E2E5ED;
    -webkit-box-shadow: none;
            box-shadow: none; }
    .uik-btn__base.uik-btn__transparent:hover {
      border: 1px solid #CACEDB; }
    .uik-btn__base.uik-btn__transparent:focus {
      border: 1px solid #BDC2D1; }
  .uik-btn__base.uik-btn__dark {
    color: white;
    background: rgba(62, 63, 66, 0.6);
    border: 1px solid rgba(62, 63, 66, 0.8);
    -webkit-box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.1), inset 0 2px 0 0 rgba(255, 255, 255, 0.06);
            box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.1), inset 0 2px 0 0 rgba(255, 255, 255, 0.06); }
    .uik-btn__base.uik-btn__dark:hover {
      background: rgba(62, 63, 66, 0.7);
      border: 1px solid rgba(62, 63, 66, 0.9);
      -webkit-box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.18), inset 0 2px 0 0 rgba(255, 255, 255, 0.06);
              box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.18), inset 0 2px 0 0 rgba(255, 255, 255, 0.06); }
      .uik-btn__base.uik-btn__dark:hover.uik-btn__noBorder {
        -webkit-box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.18);
                box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.18); }
    .uik-btn__base.uik-btn__dark:focus {
      background: rgba(62, 63, 66, 0.75);
      border: 1px solid rgba(62, 63, 66, 0.9);
      border: 1px solid #1A8628;
      -webkit-box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.1), inset 0 2px 0 0 rgba(255, 255, 255, 0.06);
              box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.1), inset 0 2px 0 0 rgba(255, 255, 255, 0.06); }
      .uik-btn__base.uik-btn__dark:focus.uik-btn__noBorder {
        -webkit-box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.1);
                box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.1); }
  .uik-btn__base.uik-btn__success {
    color: white;
    background: #38B249;
    background-image: -webkit-gradient(linear, left bottom, left top, from(#34AA44), to(#38B249));
    background-image: -webkit-linear-gradient(bottom, #34AA44 0%, #38B249 100%);
    background-image: -o-linear-gradient(bottom, #34AA44 0%, #38B249 100%);
    background-image: linear-gradient(0deg, #34AA44 0%, #38B249 100%);
    border: 1px solid #2D9C3C;
    -webkit-box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.1), inset 0 2px 0 0 rgba(255, 255, 255, 0.06);
            box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.1), inset 0 2px 0 0 rgba(255, 255, 255, 0.06); }
    .uik-btn__base.uik-btn__success:hover {
      background-image: -webkit-gradient(linear, left bottom, left top, color-stop(2%, #2CA13C), to(#2FAE40));
      background-image: -webkit-linear-gradient(bottom, #2CA13C 2%, #2FAE40 100%);
      background-image: -o-linear-gradient(bottom, #2CA13C 2%, #2FAE40 100%);
      background-image: linear-gradient(0deg, #2CA13C 2%, #2FAE40 100%);
      border: 1px solid #259835;
      -webkit-box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.18), inset 0 2px 0 0 rgba(255, 255, 255, 0.06);
              box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.18), inset 0 2px 0 0 rgba(255, 255, 255, 0.06); }
      .uik-btn__base.uik-btn__success:hover.uik-btn__noBorder {
        -webkit-box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.18);
                box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.18); }
    .uik-btn__base.uik-btn__success:focus {
      background-image: -webkit-gradient(linear, left bottom, left top, from(#259835), to(#27A337));
      background-image: -webkit-linear-gradient(bottom, #259835 0%, #27A337 100%);
      background-image: -o-linear-gradient(bottom, #259835 0%, #27A337 100%);
      background-image: linear-gradient(0deg, #259835 0%, #27A337 100%);
      border: 1px solid #1A8628;
      -webkit-box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.1), inset 0 2px 0 0 rgba(255, 255, 255, 0.06);
              box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.1), inset 0 2px 0 0 rgba(255, 255, 255, 0.06); }
      .uik-btn__base.uik-btn__success:focus.uik-btn__noBorder {
        -webkit-box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.1);
                box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.1); }
  .uik-btn__base.uik-btn__error {
    color: white;
    background: #E6492D;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#E73C1E), to(#CF3014));
    background-image: -webkit-linear-gradient(top, #E73C1E 0%, #CF3014 100%);
    background-image: -o-linear-gradient(top, #E73C1E 0%, #CF3014 100%);
    background-image: linear-gradient(-180deg, #E73C1E 0%, #CF3014 100%);
    border: 1px solid #BA0B15;
    -webkit-box-shadow: 0 1px 1px 0 rgba(43, 26, 24, 0.1), inset 0 2px 0 0 rgba(255, 255, 255, 0.08);
            box-shadow: 0 1px 1px 0 rgba(43, 26, 24, 0.1), inset 0 2px 0 0 rgba(255, 255, 255, 0.08); }
    .uik-btn__base.uik-btn__error:hover {
      background-image: -webkit-gradient(linear, left top, left bottom, from(#DF3517), to(#C42B10));
      background-image: -webkit-linear-gradient(top, #DF3517 0%, #C42B10 100%);
      background-image: -o-linear-gradient(top, #DF3517 0%, #C42B10 100%);
      background-image: linear-gradient(-180deg, #DF3517 0%, #C42B10 100%);
      border: 1px solid #B00B14;
      -webkit-box-shadow: 0 1px 1px 0 rgba(43, 26, 24, 0.18), inset 0 2px 0 0 rgba(255, 255, 255, 0.08);
              box-shadow: 0 1px 1px 0 rgba(43, 26, 24, 0.18), inset 0 2px 0 0 rgba(255, 255, 255, 0.08); }
      .uik-btn__base.uik-btn__error:hover.uik-btn__noBorder {
        -webkit-box-shadow: 0 1px 1px 0 rgba(43, 26, 24, 0.18);
                box-shadow: 0 1px 1px 0 rgba(43, 26, 24, 0.18); }
    .uik-btn__base.uik-btn__error:focus {
      background-image: -webkit-linear-gradient(269deg, #C22B10 0%, #B8280E 100%);
      background-image: -o-linear-gradient(269deg, #C22B10 0%, #B8280E 100%);
      background-image: linear-gradient(-179deg, #C22B10 0%, #B8280E 100%);
      border: 1px solid #A00A13;
      -webkit-box-shadow: 0 1px 1px 0 rgba(43, 26, 24, 0.1), inset 0 2px 0 0 rgba(255, 255, 255, 0.08);
              box-shadow: 0 1px 1px 0 rgba(43, 26, 24, 0.1), inset 0 2px 0 0 rgba(255, 255, 255, 0.08);
      border-radius: 4px; }
      .uik-btn__base.uik-btn__error:focus.uik-btn__noBorder {
        -webkit-box-shadow: 0 1px 1px 0 rgba(43, 26, 24, 0.1);
                box-shadow: 0 1px 1px 0 rgba(43, 26, 24, 0.1); }
  .uik-btn__base.uik-btn__primary {
    color: white;
    background: #1665D8;
    background-image: -webkit-linear-gradient(91deg, #1665D8 2%, #1F6FE5 98%);
    background-image: -o-linear-gradient(91deg, #1665D8 2%, #1F6FE5 98%);
    background-image: linear-gradient(-1deg, #1665D8 2%, #1F6FE5 98%);
    background-image: -webkit-gradient(linear, left bottom, left top, from(#1665D8), to(#1F6FE5));
    background-image: -webkit-linear-gradient(bottom, #1665D8 0%, #1F6FE5 100%);
    background-image: -o-linear-gradient(bottom, #1665D8 0%, #1F6FE5 100%);
    background-image: linear-gradient(0deg, #1665D8 0%, #1F6FE5 100%);
    border: 1px solid #1461D2;
    -webkit-box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1), inset 0 2px 0 0 rgba(255, 255, 255, 0.06);
            box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1), inset 0 2px 0 0 rgba(255, 255, 255, 0.06); }
    .uik-btn__base.uik-btn__primary:hover {
      background-image: -webkit-gradient(linear, left bottom, left top, from(#115ECF), to(#1767DB));
      background-image: -webkit-linear-gradient(bottom, #115ECF 0%, #1767DB 100%);
      background-image: -o-linear-gradient(bottom, #115ECF 0%, #1767DB 100%);
      background-image: linear-gradient(0deg, #115ECF 0%, #1767DB 100%);
      border: 1px solid #0E57C2;
      -webkit-box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.18), inset 0 2px 0 0 rgba(255, 255, 255, 0.06);
              box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.18), inset 0 2px 0 0 rgba(255, 255, 255, 0.06); }
      .uik-btn__base.uik-btn__primary:hover.uik-btn__noBorder {
        -webkit-box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.18);
                box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.18); }
    .uik-btn__base.uik-btn__primary:focus {
      background-image: -webkit-gradient(linear, left bottom, left top, from(#0E55BD), to(#0F5BCA));
      background-image: -webkit-linear-gradient(bottom, #0E55BD 0%, #0F5BCA 100%);
      background-image: -o-linear-gradient(bottom, #0E55BD 0%, #0F5BCA 100%);
      background-image: linear-gradient(0deg, #0E55BD 0%, #0F5BCA 100%);
      border: 1px solid #0B4AA5;
      -webkit-box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1), inset 0 2px 0 0 rgba(255, 255, 255, 0.06);
              box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1), inset 0 2px 0 0 rgba(255, 255, 255, 0.06); }
      .uik-btn__base.uik-btn__primary:focus.uik-btn__noBorder {
        -webkit-box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1);
                box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1); }
  .uik-btn__base:disabled {
    cursor: not-allowed;
    background: #747578;
    border: 1px solid #696A6E;
    -webkit-box-shadow: inset 0 2px 0 0 rgba(255, 255, 255, 0.06);
            box-shadow: inset 0 2px 0 0 rgba(255, 255, 255, 0.06);
    border-radius: 4px;
    color: #9EA0A5; }
    .uik-btn__base:disabled.uik-btn__icon {
      opacity: 0.3; }
  .uik-btn__base.uik-btn__clear {
    background: transparent;
    border: none;
    outline: none;
    -webkit-box-shadow: none;
            box-shadow: none;
    color: #9EA0A5; }
    .uik-btn__base.uik-btn__clear:hover {
      color: #6B6C6F; }

/* Necessary if icon is present in <a> element
// otherwise it's not aligned to the text */
a.uik-btn__commonButton * {
  vertical-align: middle; }

a.uik-btn__commonButton {
  text-decoration: none; }
.uik-content-title__wrapper {
  font-weight: 500;
  font-size: .75rem;
  color: #9EA0A5;
  line-height: 1.125rem;
  text-transform: uppercase; }
  .uik-content-title__wrapper + * {
    margin-top: .5rem; }
.uik-input__inputWrapper {
  position: relative; }

.uik-input__input {
  border: 1px solid #EAEDF3;
  border-radius: 4px;
  display: block;
  width: 100%;
  background: white;
  padding: 10px 15px;
  -webkit-transition: .2s all;
  -o-transition: .2s all;
  transition: .2s all;
  outline: none;
  font-size: 0.875rem; }
  .uik-input__input + * {
    margin-top: 10px; }
  .uik-input__input:disabled {
    background: rgba(255, 255, 255, 0.3); }
  .uik-input__input:focus {
    border-color: #1665D8; }
  .uik-input__input::-webkit-input-placeholder, .uik-input__input::-moz-placeholder, .uik-input__input:-ms-input-placeholder, .uik-input__input:-moz-placeholder {
    color: #9EA0A5; }
  .uik-input__input.uik-input__errorHighlight {
    border-color: #E6492D; }

.uik-input__errorMessage {
  color: #E6492D;
  margin-top: 6px;
  line-height: 1.375rem; }

.uik-input__iconWrapper {
  position: absolute;
  top: 50%;
  color: #D8DCE6;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%); }
  .uik-input__iconWrapper:not(.uik-input__iconWrapperRight) {
    left: 12px; }
    .uik-input__iconWrapper:not(.uik-input__iconWrapperRight) + input {
      padding-left: 40px; }
  .uik-input__iconWrapper.uik-input__iconWrapperRight {
    left: auto;
    right: 12px; }
    .uik-input__iconWrapper.uik-input__iconWrapperRight + input {
      padding-right: 40px; }

.uik-input__clear .uik-input__iconWrapper:not(.uik-input__iconWrapperRight) {
  left: 0; }
  .uik-input__clear .uik-input__iconWrapper:not(.uik-input__iconWrapperRight) + input {
    padding-left: 28px; }

.uik-input__clear .uik-input__iconWrapper.uik-input__iconWrapperRight {
  right: 0; }
  .uik-input__clear .uik-input__iconWrapper.uik-input__iconWrapperRight + input {
    padding-right: 28px; }

.uik-input__clear input {
  padding: 0;
  border: none !important;
  background: transparent; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: background-color 5500s ease-in-out 0s !important;
  -o-transition: background-color 5500s ease-in-out 0s !important;
  transition: background-color 5500s ease-in-out 0s !important;
  color: #6B6C6F !important;
  -webkit-text-fill-color: #6B6C6F !important; }
.uik-checkbox__wrapper {
  display: inline-block;
  line-height: 17px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .uik-checkbox__wrapper .uik-checkbox__checkbox {
    width: 0px;
    height: 0px;
    overflow: hidden;
    position: absolute;
    visibility: hidden; }
  .uik-checkbox__wrapper .uik-checkbox__description {
    margin-top: 2px;
    margin-left: 26px;
    color: #9EA0A5; }
  .uik-checkbox__wrapper .uik-checkbox__label {
    color: #6B6C6F;
    font-size: 0.875rem;
    line-height: 19px;
    color: #6B6C6F;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    position: relative;
    cursor: pointer; }
    .uik-checkbox__wrapper .uik-checkbox__label:hover {
      color: #3E3F42; }
  .uik-checkbox__wrapper .uik-checkbox__checkbox[disabled] + .uik-checkbox__label {
    cursor: not-allowed; }
  .uik-checkbox__wrapper .uik-checkbox__checkbox:checked + .uik-checkbox__label {
    color: #3E3F42; }
    .uik-checkbox__wrapper .uik-checkbox__checkbox:checked + .uik-checkbox__label:hover {
      color: #6B6C6F; }

.uik-checkbox__wrapper:not(.uik-checkbox__toggle):not(.uik-checkbox__radio) .uik-checkbox__label:before {
  text-align: center;
  line-height: 14px;
  padding-left: 1px;
  color: #fff;
  width: 16px;
  height: 16px;
  content: "";
  font-size: 7px;
  background-color: #FFF;
  border: 1px solid #DFE3E9;
  border-radius: 3px;
  margin-right: 10px;
  display: inline-block;
  -webkit-transition: border .2s, background .2s;
  -o-transition: border .2s, background .2s;
  transition: border .2s, background .2s;
  vertical-align: middle;
  -webkit-transform: translateY(1px);
      -ms-transform: translateY(1px);
          transform: translateY(1px); }

.uik-checkbox__wrapper:not(.uik-checkbox__toggle):not(.uik-checkbox__radio) .uik-checkbox__label .uik-checkbox__checkboxIcon {
  position: absolute;
  left: 4px;
  top: 6px;
  display: none; }

.uik-checkbox__wrapper:not(.uik-checkbox__toggle):not(.uik-checkbox__radio) input[disabled] + .uik-checkbox__label:before {
  /* Checkbox: */
  background-image: -webkit-gradient(linear, left bottom, left top, from(#F6F7F9), to(#FFFFFF));
  background-image: -webkit-linear-gradient(bottom, #F6F7F9 0%, #FFFFFF 100%);
  background-image: -o-linear-gradient(bottom, #F6F7F9 0%, #FFFFFF 100%);
  background-image: linear-gradient(0deg, #F6F7F9 0%, #FFFFFF 100%);
  border: 1px solid #D8DCE6;
  -webkit-box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.05), inset 0 2px 0 0 rgba(255, 255, 255, 0.05);
          box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.05), inset 0 2px 0 0 rgba(255, 255, 255, 0.05);
  border-radius: 3px;
  /* Rectangle: */
  background: rgba(188, 188, 188, 0.25); }

.uik-checkbox__wrapper:not(.uik-checkbox__toggle):not(.uik-checkbox__radio) .uik-checkbox__checkbox:checked + .uik-checkbox__label:before {
  background-color: #38B249;
  border-color: #38B249; }

.uik-checkbox__wrapper:not(.uik-checkbox__toggle):not(.uik-checkbox__radio) .uik-checkbox__checkbox:checked + .uik-checkbox__label.green:before {
  color: white;
  background-color: #38B249;
  border-color: #38B249; }

.uik-checkbox__wrapper:not(.uik-checkbox__toggle):not(.uik-checkbox__radio) .uik-checkbox__checkbox:checked + .uik-checkbox__label.blue:before {
  color: white;
  background-color: #1665D8;
  border-color: #1665D8; }

.uik-checkbox__wrapper:not(.uik-checkbox__toggle):not(.uik-checkbox__radio) .uik-checkbox__checkbox:checked + .uik-checkbox__label.orange:before {
  color: white;
  background-color: #F6AB2F;
  border-color: #F6AB2F; }

.uik-checkbox__wrapper:not(.uik-checkbox__toggle):not(.uik-checkbox__radio) .uik-checkbox__checkbox:checked + .uik-checkbox__label.violet:before {
  color: white;
  background-color: #6977FF;
  border-color: #6977FF; }

.uik-checkbox__wrapper:not(.uik-checkbox__toggle):not(.uik-checkbox__radio) .uik-checkbox__checkbox:checked + .uik-checkbox__label.yellow:before {
  color: white;
  background-color: #FACF55;
  border-color: #FACF55; }

.uik-checkbox__wrapper:not(.uik-checkbox__toggle):not(.uik-checkbox__radio) .uik-checkbox__checkbox:checked + .uik-checkbox__label.red:before {
  color: white;
  background-color: #E6492D;
  border-color: #E6492D; }

.uik-checkbox__wrapper:not(.uik-checkbox__toggle):not(.uik-checkbox__radio) .uik-checkbox__checkbox:checked + .uik-checkbox__label .uik-checkbox__checkboxIcon {
  display: block; }

.uik-checkbox__wrapper.uik-checkbox__toggle .uik-checkbox__description {
  margin-left: 50px; }

.uik-checkbox__wrapper.uik-checkbox__toggle .uik-checkbox__label:before, .uik-checkbox__wrapper.uik-checkbox__toggle .uik-checkbox__label:after {
  content: '';
  display: block;
  border-radius: 100px;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s; }

.uik-checkbox__wrapper.uik-checkbox__toggle .uik-checkbox__label:before {
  width: 38px;
  height: 20px;
  background: #EAEDF3;
  border: 1px solid #EAEDF3;
  margin-right: 12px; }

.uik-checkbox__wrapper.uik-checkbox__toggle .uik-checkbox__label:after {
  height: 16px;
  width: 16px;
  background: #FFFFFF;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 2px;
  left: 2px; }

.uik-checkbox__wrapper.uik-checkbox__toggle .uik-checkbox__checkbox:checked + .uik-checkbox__label:before {
  background: #34AA44;
  border: 1px solid #2F9E3E; }

.uik-checkbox__wrapper.uik-checkbox__toggle .uik-checkbox__checkbox:checked + .uik-checkbox__label:after {
  -webkit-transform: translateX(18px);
      -ms-transform: translateX(18px);
          transform: translateX(18px); }

.uik-checkbox__wrapper.uik-checkbox__toggle .uik-checkbox__checkbox[disabled] + .uik-checkbox__label:after {
  opacity: 0.6; }

.uik-checkbox__wrapper.uik-checkbox__radio .uik-checkbox__label:before, .uik-checkbox__wrapper.uik-checkbox__radio .uik-checkbox__label:after {
  content: '';
  display: block;
  border-radius: 100px;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s; }

.uik-checkbox__wrapper.uik-checkbox__radio .uik-checkbox__label:before {
  margin-right: 10px;
  width: 16px;
  height: 16px;
  /* Radio: */
  background-image: -webkit-gradient(linear, left bottom, left top, from(#F6F7F9), to(#FFFFFF));
  background-image: -webkit-linear-gradient(bottom, #F6F7F9 0%, #FFFFFF 100%);
  background-image: -o-linear-gradient(bottom, #F6F7F9 0%, #FFFFFF 100%);
  background-image: linear-gradient(0deg, #F6F7F9 0%, #FFFFFF 100%);
  border: 1px solid #D8DCE6;
  -webkit-box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.05), inset 0 2px 0 0 rgba(255, 255, 255, 0.05);
          box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.05), inset 0 2px 0 0 rgba(255, 255, 255, 0.05); }

.uik-checkbox__wrapper.uik-checkbox__radio .uik-checkbox__label:after {
  height: 6px;
  width: 6px;
  background: #FFFFFF;
  position: absolute;
  top: 5px;
  left: 5px;
  opacity: 0; }

.uik-checkbox__wrapper.uik-checkbox__radio .uik-checkbox__checkbox:checked + .uik-checkbox__label:before {
  background-image: -webkit-gradient(linear, left bottom, left top, from(#34AA44), to(#38B249));
  background-image: -webkit-linear-gradient(bottom, #34AA44 0%, #38B249 100%);
  background-image: -o-linear-gradient(bottom, #34AA44 0%, #38B249 100%);
  background-image: linear-gradient(0deg, #34AA44 0%, #38B249 100%);
  border: 1px solid #2D9C3C;
  -webkit-box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.1), inset 0 2px 0 0 rgba(255, 255, 255, 0.06);
          box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.1), inset 0 2px 0 0 rgba(255, 255, 255, 0.06); }

.uik-checkbox__wrapper.uik-checkbox__radio .uik-checkbox__checkbox:checked + .uik-checkbox__label:after {
  opacity: 1; }

.uik-checkbox__wrapper.uik-checkbox__radio .uik-checkbox__checkbox[disabled] + .uik-checkbox__label:before {
  background: rgba(188, 188, 188, 0.25); }
.uik-form-input-group__vertical {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: -8px;
  margin-bottom: -8px; }
  .uik-form-input-group__vertical > *:not(.uik-form-input-group__horizontal) {
    margin-top: 8px;
    margin-bottom: 8px; }
  .uik-form-input-group__vertical > .uik-form-input-group__horizontal {
    margin-top: 0;
    margin-bottom: 0; }

.uik-form-input-group__horizontal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: -8px; }
  .uik-form-input-group__horizontal > * {
    margin: 8px 8px;
    -ms-flex-negative: 1;
        flex-shrink: 1;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -ms-flex-preferred-size: 1px;
        flex-basis: 1px;
    min-width: 130px; }
  .uik-form-input-group__horizontal > .uik-form-input-group__vertical {
    margin-top: 0;
    margin-bottom: 0; }
.uik-select__wrapper {
  position: relative;
  font-size: 0.875rem;
  display: inline-block; }
  .uik-select__wrapper .uik-select__valueRendered {
    width: 100%;
    text-align: left;
    position: relative;
    background: #FFFFFF;
    border: 1px solid #D8DCE6;
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08); }
    .uik-select__wrapper .uik-select__valueRendered .uik-select__label {
      position: absolute;
      top: -18px;
      font-size: 1rem;
      left: 14px;
      pointer-events: none; }
    .uik-select__wrapper .uik-select__valueRendered .uik-select__valueRenderedWrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      height: 100%;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      width: 100%; }
      .uik-select__wrapper .uik-select__valueRendered .uik-select__valueRenderedWrapper > * {
        -webkit-box-flex: 1;
            -ms-flex-positive: 1;
                flex-grow: 1;
        max-width: 100%;
        min-width: 1px; }
      .uik-select__wrapper .uik-select__valueRendered .uik-select__valueRenderedWrapper .uik-select__arrowWrapper {
        -webkit-box-flex: 0;
            -ms-flex-positive: 0;
                flex-grow: 0;
        -webkit-transition: 0.2s color;
        -o-transition: 0.2s color;
        transition: 0.2s color;
        position: relative;
        width: 1px;
        height: 100%;
        margin-left: 12px; }
        .uik-select__wrapper .uik-select__valueRendered .uik-select__valueRenderedWrapper .uik-select__arrowWrapper:before, .uik-select__wrapper .uik-select__valueRendered .uik-select__valueRenderedWrapper .uik-select__arrowWrapper:after {
          display: block;
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          width: 0;
          height: 0;
          border-left: 3px solid transparent;
          border-right: 3px solid transparent;
          border-bottom: 3px solid #9EA0A5; }
        .uik-select__wrapper .uik-select__valueRendered .uik-select__valueRenderedWrapper .uik-select__arrowWrapper:after {
          top: -4px; }
        .uik-select__wrapper .uik-select__valueRendered .uik-select__valueRenderedWrapper .uik-select__arrowWrapper:before {
          top: 2px;
          -webkit-transform: rotate(180deg);
              -ms-transform: rotate(180deg);
                  transform: rotate(180deg); }
  .uik-select__wrapper .uik-select__valueWrapper {
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    white-space: nowrap; }
    .uik-select__wrapper .uik-select__valueWrapper.uik-select__placeholderEmpty {
      color: #9EA0A5; }

.uik-select__optionListWrapper {
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid #D8DCE6;
  background: white;
  border-radius: 4px;
  position: absolute !important;
  z-index: 1000;
  top: 100%;
  right: 0;
  -webkit-transform: translateY(6px);
      -ms-transform: translateY(6px);
          transform: translateY(6px);
  max-height: 306px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-line-pack: stretch;
      align-content: stretch;
  min-width: 140px; }
  .uik-select__optionListWrapper > * {
    min-height: 1px; }
  .uik-select__optionListWrapper.uik-select__bottomLeft {
    left: 0;
    right: auto; }
  .uik-select__optionListWrapper.uik-select__topLeft, .uik-select__optionListWrapper.uik-select__topRight {
    left: 0;
    right: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 4px; }
  .uik-select__optionListWrapper.uik-select__topRight {
    left: auto;
    right: 0; }
  .uik-select__optionListWrapper .uik-select__optionList {
    width: 100%;
    min-height: 1px;
    max-height: 235px;
    position: relative;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; }
    .uik-select__optionListWrapper .uik-select__optionList > * {
      width: 100%; }
    .uik-select__optionListWrapper .uik-select__optionList .uik-select__option,
    .uik-select__optionListWrapper .uik-select__optionList .uik-select__option:focus {
      width: 100%;
      outline: none;
      cursor: pointer;
      width: 100%;
      background: transparent;
      border: none;
      text-align: left;
      border-radius: 0;
      min-height: 38px; }
      .uik-select__optionListWrapper .uik-select__optionList .uik-select__option .uik-select__optionContent,
      .uik-select__optionListWrapper .uik-select__optionList .uik-select__option:focus .uik-select__optionContent {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
        .uik-select__optionListWrapper .uik-select__optionList .uik-select__option .uik-select__optionContent > *,
        .uik-select__optionListWrapper .uik-select__optionList .uik-select__option:focus .uik-select__optionContent > * {
          min-width: 1px;
          -o-text-overflow: ellipsis;
             text-overflow: ellipsis;
          white-space: nowrap; }
        .uik-select__optionListWrapper .uik-select__optionList .uik-select__option .uik-select__optionContent > *:first-child,
        .uik-select__optionListWrapper .uik-select__optionList .uik-select__option:focus .uik-select__optionContent > *:first-child {
          -webkit-box-flex: 1;
              -ms-flex-positive: 1;
                  flex-grow: 1;
          -ms-flex-negative: 1;
              flex-shrink: 1; }
      .uik-select__optionListWrapper .uik-select__optionList .uik-select__option .uik-select__check,
      .uik-select__optionListWrapper .uik-select__optionList .uik-select__option:focus .uik-select__check {
        color: #38B249;
        margin-left: 12px;
        -ms-flex-negative: 0;
            flex-shrink: 0;
        position: relative;
        width: 5px;
        height: 5px;
        background: #38B249;
        border-radius: 50%; }
      .uik-select__optionListWrapper .uik-select__optionList .uik-select__option:hover,
      .uik-select__optionListWrapper .uik-select__optionList .uik-select__option:focus:hover {
        border: none !important; }
      .uik-select__optionListWrapper .uik-select__optionList .uik-select__option:not(:last-child), .uik-select__optionListWrapper .uik-select__optionList .uik-select__option:not(:last-child):hover,
      .uik-select__optionListWrapper .uik-select__optionList .uik-select__option:focus:not(:last-child),
      .uik-select__optionListWrapper .uik-select__optionList .uik-select__option:focus:not(:last-child):hover {
        border-bottom: 1px solid #EAEDF3 !important; }
    .uik-select__optionListWrapper .uik-select__optionList .uik-select__option:focus {
      color: #3E3F42; }

.uik-select__placeholderAndOther {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }
  .uik-select__placeholderAndOther > *:first-child {
    min-width: 5px;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    white-space: nowrap; }
  .uik-select__placeholderAndOther .uik-select__plusValue {
    display: inline-block;
    background: #D8DCE6;
    border: 1px solid #EAEDF3;
    height: 20px;
    border-radius: 100px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0 5px;
    min-width: 30px;
    margin-left: 10px;
    font-size: 0.7rem; }
.uik-map-pin-circle__wrapper {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background: #1665D8;
  background-image: -webkit-gradient(linear, right top, left top, from(#4187EE), to(#4F95FC));
  background-image: -webkit-linear-gradient(right, #4187EE 0%, #4F95FC 100%);
  background-image: -o-linear-gradient(right, #4187EE 0%, #4F95FC 100%);
  background-image: linear-gradient(-90deg, #4187EE 0%, #4F95FC 100%);
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1.125rem;
  color: white; }
  .uik-map-pin-circle__wrapper > * {
    z-index: 10; }
  .uik-map-pin-circle__wrapper:before {
    content: '';
    display: block;
    position: absolute;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    top: -5px;
    left: -5px;
    background: #1665D8;
    background-image: -webkit-gradient(linear, right top, left top, from(#4187EE), to(#4F95FC));
    background-image: -webkit-linear-gradient(right, #4187EE 0%, #4F95FC 100%);
    background-image: -o-linear-gradient(right, #4187EE 0%, #4F95FC 100%);
    background-image: linear-gradient(-90deg, #4187EE 0%, #4F95FC 100%);
    opacity: 0.2;
    z-index: 0; }
  .uik-map-pin-circle__wrapper.green, .uik-map-pin-circle__wrapper.green:before {
    background: #38B249;
    background-image: -webkit-linear-gradient(359deg, #39B54A 0%, #34AA44 100%);
    background-image: -o-linear-gradient(359deg, #39B54A 0%, #34AA44 100%);
    background-image: linear-gradient(-269deg, #39B54A 0%, #34AA44 100%); }
  .uik-map-pin-circle__wrapper.violet, .uik-map-pin-circle__wrapper.violet:before {
    background: #6977FF;
    background-image: -webkit-gradient(linear, right top, left top, from(#6758F3), to(#915CF4));
    background-image: -webkit-linear-gradient(right, #6758F3 0%, #915CF4 100%);
    background-image: -o-linear-gradient(right, #6758F3 0%, #915CF4 100%);
    background-image: linear-gradient(-90deg, #6758F3 0%, #915CF4 100%); }
.uik-avatar-container__wrapper {
  padding: 6px 30px; }
  .uik-avatar-container__wrapper > *:not(:last-child) {
    margin-bottom: 12px; }
.uik-emoji-headline__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.uik-emoji-headline__emojiBox {
  background: #FFFFFF;
  border: 1px solid #EAEDF3;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  font-size: 2.625rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 70px;
  height: 70px;
  line-height: 1;
  padding-top: 5px;
  padding-left: 5px;
  overflow: hidden;
  margin-right: 1.5rem;
  -ms-flex-negative: 0;
      flex-shrink: 0; }

.uik-emoji-headline__content {
  max-width: 700px; }
  .uik-emoji-headline__content h2 {
    font-size: 1.375rem;
    line-height: 2rem;
    font-weight: 500; }
  .uik-emoji-headline__content p {
    margin: 0 !important;
    color: #9EA0A5; }
.uik-icon-text__wrapper {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer; }
  .uik-icon-text__wrapper + .uik-icon-text__wrapper {
    margin-left: 15px; }
  .uik-icon-text__wrapper i {
    color: #9EA0A5;
    margin-right: 10px;
    font-size: 1.2rem; }
  .uik-icon-text__wrapper .uik-icon-text__text {
    color: #9EA0A5;
    font-size: 0.875rem; }
  .uik-icon-text__wrapper.uik-icon-text__textHighlight .uik-icon-text__text {
    color: #6B6C6F; }
  .uik-icon-text__wrapper.uik-icon-text__highlight i, .uik-icon-text__wrapper.uik-icon-text__highlighted i, .uik-icon-text__wrapper:hover i {
    color: #1665D8; }
.uik-dropdown-item__wrapper,
.uik-dropdown-item__wrapper:focus {
  border-left: none !important;
  border-top: none !important;
  border-right: none !important;
  border-radius: 0;
  font-size: 0.875rem;
  min-width: 100%;
  min-height: 38px;
  text-align: left;
  background: white; }
  .uik-dropdown-item__wrapper:last-child,
  .uik-dropdown-item__wrapper:focus:last-child {
    border-bottom: none !important; }
  .uik-dropdown-item__wrapper:hover,
  .uik-dropdown-item__wrapper:focus:hover {
    background: #F6F7F9; }
  .uik-dropdown-item__wrapper > *,
  .uik-dropdown-item__wrapper:focus > * {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
.uik-menuDrop__defaultDisplayComponent {
  width: 36px;
  padding: 0;
  text-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-color: #D8DCE6; }

.uik-menuDrop__btnContent {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.uik-menuDrop__list {
  position: absolute;
  z-index: 50;
  background: white;
  border: 1px solid #D8DCE6;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  list-style: none;
  top: 100%;
  margin-top: 4px;
  right: 0;
  min-width: 140px; }
  .uik-menuDrop__list > *:first-child {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important; }
  .uik-menuDrop__list > *:last-child {
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important; }
  .uik-menuDrop__list.uik-menuDrop__bottomLeft {
    left: 0;
    right: auto; }
  .uik-menuDrop__list.uik-menuDrop__topLeft, .uik-menuDrop__list.uik-menuDrop__topRight {
    left: 0;
    right: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 4px; }
  .uik-menuDrop__list.uik-menuDrop__topRight {
    left: auto;
    right: 0; }
.uik-top-bar__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 70px;
  background: #FFFFFF;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  z-index: 100;
  border-bottom: 1px solid #EAEDF3; }
  .uik-top-bar__wrapper.uik-top-bar__center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  @media screen and (max-width: 768px) {
    .uik-top-bar__wrapper {
      height: 56px; } }

.uik-top-bar__pageName {
  /* Socialio: */
  font-weight: 500;
  font-size: 1rem;
  color: #3E3F42;
  line-height: 1.75; }
  .uik-top-bar__pageName i {
    color: #1665D8;
    margin-right: 14px; }
.uik-top-bar-section__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 30px; }
.uik-top-bar-link__wrapper {
  height: 38px;
  line-height: 38px;
  padding: 0 15px;
  display: block;
  cursor: pointer;
  font-size: .875rem;
  color: #3E3F42; }
  .uik-top-bar-link__wrapper:hover, .uik-top-bar-link__wrapper.active {
    color: #1665D8; }
.uik-top-bar-link-container__wrapper {
  margin: 0 -15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
.uik-top-bar-title__wrapper {
  font-weight: 500;
  font-size: 1rem;
  color: #3E3F42;
  line-height: 1.75;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .uik-top-bar-title__wrapper.uik-top-bar-title__large {
    font-size: 1.125rem; }
  .uik-top-bar-title__wrapper i {
    margin-right: 14px;
    color: #9EA0A5; }
.uik-nav-divider__wrapper {
  background: #EAEDF3;
  height: 38px;
  width: 1px;
  margin: 0 30px;
  -ms-flex-preferred-size: 1px;
      flex-basis: 1px;
  -ms-flex-negative: 0;
      flex-shrink: 0; }
.uik-nav-link__wrapper {
  font-size: .875rem;
  color: #3E3F42;
  display: block;
  padding: 0 30px;
  min-height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  .uik-nav-link__wrapper .uik-nav-link__icon {
    display: inline-block;
    margin-right: 12px;
    width: 18px;
    text-align: center; }
  .uik-nav-link__wrapper .uik-nav-link__rightEl {
    font-size: 0.75rem;
    color: #9EA0A5;
    line-height: 1rem; }
  .uik-nav-link__wrapper.uik-nav-link__highlighted .uik-nav-link__text {
    font-weight: 500; }
  .uik-nav-link__wrapper.active {
    font-weight: 500;
    color: #1665D8;
    background: rgba(84, 147, 245, 0.05);
    -webkit-box-shadow: inset 3px 0 0 0 #1665D8;
            box-shadow: inset 3px 0 0 0 #1665D8; }
  .uik-nav-link__wrapper:hover {
    color: #1665D8;
    background: rgba(84, 147, 245, 0.05); }
.uik-nav-link__wrapperDark {
  margin: 0 15px;
  border: none;
  border-radius: 4px;
  padding: 0 15px;
  font-weight: 500;
  color: white; }
  .uik-nav-link__wrapperDark > * {
    color: white; }
  .uik-nav-link__wrapperDark.active {
    background: #1665D8;
    color: white; }
    .uik-nav-link__wrapperDark.active .uik-nav-link__rightEl {
      color: white; }
  .uik-nav-link__wrapperDark:not(.active):hover {
    color: white;
    background: rgba(255, 255, 255, 0.05);
    border: none;
    -webkit-box-shadow: none;
            box-shadow: none; }
.uik-nav-link-2__wrapper {
  font-size: .875rem;
  color: #3E3F42;
  display: block;
  min-height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 1px solid #EAEDF3; }
  .uik-nav-link-2__wrapper .uik-nav-link-2__text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .uik-nav-link-2__wrapper .uik-nav-link-2__icon {
    display: inline-block;
    margin-right: 12px;
    color: #9EA0A5;
    width: 18px;
    text-align: center;
    font-size: 20px; }
  .uik-nav-link-2__wrapper .uik-nav-link-2__rightEl {
    font-size: 0.75rem;
    color: #9EA0A5;
    line-height: 1rem; }
  .uik-nav-link-2__wrapper.uik-nav-link-2__highlighted .uik-nav-link-2__text {
    font-weight: 500; }
  .uik-nav-link-2__wrapper.active {
    font-weight: 500; }
    .uik-nav-link-2__wrapper.active .uik-nav-link-2__icon {
      color: #1665D8; }
  .uik-nav-link-2__wrapper:hover .uik-nav-link-2__icon {
    color: rgba(22, 101, 216, 0.8); }
.uik-star-rating__wrapper i {
  color: #EAEDF3; }
  .uik-star-rating__wrapper i + * {
    margin-left: 2px; }

.uik-star-rating__wrapper i.uik-star-rating__fill {
  color: #FACF55; }
.uik-nav-section__wrapper + .uik-nav-section__wrapper {
  margin-top: 20px; }

.uik-nav-section__wrapper:last-child {
  margin-bottom: 20px; }

.uik-nav-section__wrapper:first-child {
  margin-top: 15px; }
.uik-nav-link-secondary__wrapper {
  font-size: .875rem;
  color: #6B6C6F;
  display: block;
  padding: 0 30px 0 46px;
  min-height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  .uik-nav-link-secondary__wrapper .uik-nav-link-secondary__icon {
    display: inline-block;
    margin-right: 12px; }
  .uik-nav-link-secondary__wrapper .uik-nav-link-secondary__rightEl {
    font-size: 0.75rem;
    color: #9EA0A5;
    line-height: 1rem; }
  .uik-nav-link-secondary__wrapper.uik-nav-link-secondary__highlighted .uik-nav-link-secondary__text {
    color: #3E3F42;
    font-weight: 500; }
  .uik-nav-link-secondary__wrapper.active {
    font-weight: 500;
    color: #1665D8;
    background: rgba(84, 147, 245, 0.05);
    -webkit-box-shadow: inset 3px 0 0 0 #1665D8;
            box-shadow: inset 3px 0 0 0 #1665D8; }
  .uik-nav-link-secondary__wrapper:hover {
    color: #1665D8;
    background: rgba(84, 147, 245, 0.05); }
.uik-nav-title__wrapper {
  padding: 25px 30px 20px;
  font-size: 1.125rem;
  color: #3E3F42;
  display: block;
  font-weight: 500;
  line-height: 1; }
.uik-tutorial-list__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  @media screen and (max-width: 1200px) {
    .uik-tutorial-list__wrapper {
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
      .uik-tutorial-list__wrapper > * {
        -ms-flex-preferred-size: calc(33% - 30px) !important;
            flex-basis: calc(33% - 30px) !important; } }
  @media screen and (max-width: 960px) {
    .uik-tutorial-list__wrapper {
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
      .uik-tutorial-list__wrapper > * {
        -ms-flex-preferred-size: calc(50% - 30px) !important;
            flex-basis: calc(50% - 30px) !important; } }
  @media screen and (max-width: 620px) {
    .uik-tutorial-list__wrapper {
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
      .uik-tutorial-list__wrapper > * {
        -ms-flex-preferred-size: calc(100% - 30px) !important;
            flex-basis: calc(100% - 30px) !important; } }
.uik-nav-section-title__wrapper {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.75rem;
  color: #9EA0A5;
  line-height: 1.5;
  display: block;
  padding: 10px 30px; }
.uik-nav-user__wrapper {
  padding: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .uik-nav-user__wrapper .uik-nav-user__avatarWrapper + * {
    margin-top: 14px; }
  .uik-nav-user__wrapper .uik-nav-user__avatarWrapper .uik-nav-user__avatar {
    height: 100px;
    width: 100px;
    border-radius: 50%; }
  .uik-nav-user__wrapper .uik-nav-user__name {
    color: #3E3F42;
    font-weight: 500;
    line-height: 1.5rem;
    font-size: 1rem; }
  .uik-nav-user__wrapper .uik-nav-user__textTop {
    color: #9EA0A5;
    font-size: .875rem; }
.uik-headline__wrapper {
  font-size: 1.625rem;
  line-height: 2.375rem;
  font-weight: 400; }
.uik-headline-desc__wrapper + * {
  margin-top: 30px; }
.uik-tutorial-list-item__wrapper {
  -ms-flex-preferred-size: 350px;
      flex-basis: 350px;
  -ms-flex-negative: 1;
      flex-shrink: 1; }

.uik-tutorial-list-item__cover {
  background: no-repeat center center;
  background-size: cover;
  height: 190px; }

.uik-tutorial-list-item__content {
  padding: 24px 30px; }
  .uik-tutorial-list-item__content h3 {
    font-weight: 500; }
  .uik-tutorial-list-item__content p {
    color: #9EA0A5;
    margin-top: 0.7rem; }
  .uik-tutorial-list-item__content .uik-tutorial-list-item__meta {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .uik-tutorial-list-item__content .uik-tutorial-list-item__meta .uik-tutorial-list-item__duration {
      font-size: .875rem;
      color: #9EA0A5; }

.uik-tutorial-list-item__extra {
  border-top: 1px solid #EAEDF3;
  padding: 15px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
.uik-widget__wrapper {
  border: 1px solid #EAEDF3;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  background: #FFFFFF;
  display: block; }
  .uik-widget__wrapper.uik-widget__padding {
    padding: 30px; }
  .uik-widget__wrapper.uik-widget__margin {
    margin: 15px; }
  .uik-widget__wrapper h1,
  .uik-widget__wrapper h2,
  .uik-widget__wrapper h3,
  .uik-widget__wrapper h4,
  .uik-widget__wrapper h5 {
    font-weight: 500; }
  .uik-widget__wrapper h3 {
    font-size: 1.125rem;
    line-height: 1.75rem; }
.uik-icon-wrap__wrapper {
  color: #9EA0A5;
  background-color: rgba(158, 160, 165, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  font-size: 1.25rem; }
  .uik-icon-wrap__wrapper.uik-icon-wrap__circle {
    height: 62px;
    width: 62px;
    border-radius: 50%; }
  .uik-icon-wrap__wrapper.uik-icon-wrap__square {
    height: 38px;
    width: 38px;
    border-radius: 4px; }
  .uik-icon-wrap__wrapper.green {
    background-color: rgba(56, 178, 73, 0.1);
    color: #38B249; }
  .uik-icon-wrap__wrapper.blue {
    background-color: rgba(22, 101, 216, 0.1);
    color: #1665D8; }
  .uik-icon-wrap__wrapper.orange {
    background-color: rgba(246, 171, 47, 0.1);
    color: #F6AB2F; }
  .uik-icon-wrap__wrapper.violet {
    background-color: rgba(105, 119, 255, 0.1);
    color: #6977FF; }
  .uik-icon-wrap__wrapper.yellow {
    background-color: rgba(250, 207, 85, 0.1);
    color: #FACF55; }
  .uik-icon-wrap__wrapper.red {
    background-color: rgba(230, 73, 45, 0.1);
    color: #E6492D; }
div.uik-knowledge-topic__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s; }
  div.uik-knowledge-topic__wrapper + * {
    margin-top: 30px; }
  div.uik-knowledge-topic__wrapper .uik-knowledge-topic__content {
    margin-left: 24px;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1; }
    div.uik-knowledge-topic__wrapper .uik-knowledge-topic__content + * {
      margin-left: 24px; }
    div.uik-knowledge-topic__wrapper .uik-knowledge-topic__content .uik-knowledge-topic__title {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
    div.uik-knowledge-topic__wrapper .uik-knowledge-topic__content .uik-knowledge-topic__tag {
      margin-left: 10px; }
  div.uik-knowledge-topic__wrapper .uik-knowledge-topic__action {
    margin-left: 20px;
    display: block; }
  div.uik-knowledge-topic__wrapper .uik-knowledge-topic__users {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end; }
    div.uik-knowledge-topic__wrapper .uik-knowledge-topic__users > * + * {
      margin-left: 10px; }
  div.uik-knowledge-topic__wrapper .uik-knowledge-topic__link:hover {
    color: #1665D8; }
  @media screen and (max-width: 880px) {
    div.uik-knowledge-topic__wrapper {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      padding-left: 102px !important;
      position: relative;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
      div.uik-knowledge-topic__wrapper .uik-knowledge-topic__icon {
        position: absolute;
        top: 30px;
        left: 30px; }
      div.uik-knowledge-topic__wrapper .uik-knowledge-topic__content {
        -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
        margin-bottom: 25px; } }
  @media screen and (max-width: 550px) {
    div.uik-knowledge-topic__wrapper {
      padding-left: 15px !important;
      padding-top: 112px !important;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
      div.uik-knowledge-topic__wrapper .uik-knowledge-topic__content {
        text-align: center; }
        div.uik-knowledge-topic__wrapper .uik-knowledge-topic__content h2 {
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center; }
      div.uik-knowledge-topic__wrapper .uik-knowledge-topic__icon {
        left: 50%;
        -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
                transform: translateX(-50%); } }

.uik-knowledge-topic__dropdownIcon {
  font-size: .7rem;
  color: #9EA0A5; }
.uik-social-header__cover {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 308px;
  border-radius: 4px 4px 0 0;
  position: relative; }
  .uik-social-header__cover:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(9%, rgba(0, 0, 0, 0)), color-stop(99%, rgba(0, 0, 0, 0.35)));
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 9%, rgba(0, 0, 0, 0.35) 99%);
    background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0) 9%, rgba(0, 0, 0, 0.35) 99%);
    background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 9%, rgba(0, 0, 0, 0.35) 99%);
    border-radius: 4px 4px 0 0; }
  .uik-social-header__cover .uik-social-header__btnUpdateCover {
    position: absolute;
    bottom: 10px;
    right: 10px; }
    @media screen and (max-width: 768px) {
      .uik-social-header__cover .uik-social-header__btnUpdateCover {
        bottom: auto;
        top: 10px; } }
  .uik-social-header__cover .uik-social-header__avatar {
    position: absolute;
    bottom: -50px;
    left: 30px;
    z-index: 100; }
  .uik-social-header__cover .uik-social-header__name {
    position: absolute;
    z-index: 100;
    left: 180px;
    bottom: 15px;
    font-weight: 500;
    font-size: 1.375rem;
    color: #FFFFFF;
    line-height: 2rem; }
  @media screen and (max-width: 768px) {
    .uik-social-header__cover {
      height: 200px; }
      .uik-social-header__cover .uik-social-header__avatar,
      .uik-social-header__cover .uik-social-header__name {
        left: 50%;
        -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
                transform: translateX(-50%); }
      .uik-social-header__cover .uik-social-header__name {
        color: #3E3F42;
        top: 270px; } }

.uik-social-header__content {
  position: relative; }
  .uik-social-header__content .uik-social-header__tabWrapper {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    position: flex;
    padding: 0 15px;
    padding-left: 165px; }
    .uik-social-header__content .uik-social-header__tabWrapper > * {
      padding: 0;
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1; }
      @media screen and (max-width: 768px) {
        .uik-social-header__content .uik-social-header__tabWrapper > * {
          -webkit-box-pack: justify;
              -ms-flex-pack: justify;
                  justify-content: space-between; } }
  @media screen and (max-width: 768px) {
    .uik-social-header__content {
      margin-top: 100px; }
      .uik-social-header__content .uik-social-header__tabWrapper {
        padding: 0 15px; } }
.uik-tab__item {
  height: 70px;
  border-bottom: 3px solid transparent;
  margin: 0 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  color: #6B6C6F; }
  @media screen and (max-width: 768px) {
    .uik-tab__item {
      height: 56px; } }
  .uik-tab__item .uik-tab__extra {
    color: #9EA0A5;
    font-weight: 400;
    display: inline-block;
    margin-left: 5px;
    font-size: .875rem; }
  .uik-tab__item:hover {
    color: #3E3F42;
    border-bottom-color: rgba(22, 101, 216, 0.3); }
  .uik-tab__item.active {
    border-bottom-color: #1665D8;
    font-weight: 500;
    color: #3E3F42; }
  .uik-tab__item.uik-tab__small, .uik-tab__item.uik-tab__smaller {
    height: 62px;
    font-size: .875rem; }
    .uik-tab__item.uik-tab__small .uik-tab__extra, .uik-tab__item.uik-tab__smaller .uik-tab__extra {
      font-size: .75rem; }
  .uik-tab__item.uik-tab__smaller {
    height: 50px; }
.uik-tab__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 15px;
  -ms-flex-negative: 0;
      flex-shrink: 0; }
  .uik-tab__container:not(:last-child) {
    border-bottom: 1px solid #EAEDF3; }
.uik-social-friends__cover {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 308px;
  border-radius: 4px 4px 0 0;
  position: relative; }
  .uik-social-friends__cover:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(9%, rgba(0, 0, 0, 0)), color-stop(99%, rgba(0, 0, 0, 0.35)));
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 9%, rgba(0, 0, 0, 0.35) 99%);
    background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0) 9%, rgba(0, 0, 0, 0.35) 99%);
    background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 9%, rgba(0, 0, 0, 0.35) 99%);
    border-radius: 4px 4px 0 0; }
  .uik-social-friends__cover .uik-social-friends__btnUpdateCover {
    position: absolute;
    bottom: 10px;
    right: 10px; }

.uik-social-friends__content {
  position: relative; }
  .uik-social-friends__content .uik-social-friends__avatar {
    position: absolute;
    top: -14px;
    left: 30px;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%); }
  .uik-social-friends__content .uik-social-friends__name {
    position: absolute;
    left: 180px;
    top: -47px;
    font-weight: 500;
    font-size: 1.375rem;
    color: #FFFFFF;
    line-height: 2rem; }
.uik-widget-title__wrapper {
  padding: 18px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .uik-widget-title__wrapper:not(:last-child) {
    border-bottom: 1px solid #EAEDF3; }
  .uik-widget-title__wrapper h3 {
    /* Title: */
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.75rem; }
    .uik-widget-title__wrapper h3 + * {
      margin-top: 0; }
  .uik-widget-title__wrapper.uik-widget-title__noDivider {
    padding-bottom: 5px;
    border-bottom: none; }
.uik-social-friend-item__wrapper {
  border: 1px solid #EAEDF3;
  border-radius: 4px;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
.uik-social-friend-list__wrapper {
  margin: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .uik-social-friend-list__wrapper > * {
    margin: 10px;
    -ms-flex-preferred-size: calc(50% - 20px);
        flex-basis: calc(50% - 20px); }
    @media screen and (max-width: 768px) {
      .uik-social-friend-list__wrapper > * {
        -ms-flex-preferred-size: calc(100% - 20px);
            flex-basis: calc(100% - 20px); } }
.uik-social-about__wrapper .uik-social-about__listWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 5px 22.75px; }
  .uik-social-about__wrapper .uik-social-about__listWrapper > * {
    -ms-flex-preferred-size: calc(100% - 15px);
        flex-basis: calc(100% - 15px);
    border-bottom: 1px solid #EAEDF3;
    margin: 0 7.5px;
    padding: 20px 0;
    max-width: calc(100% - 15px); }
  .uik-social-about__wrapper .uik-social-about__listWrapper > *:last-child {
    border-bottom: none; }
  @media screen and (min-width: 1400px) {
    .uik-social-about__wrapper .uik-social-about__listWrapper > * {
      -ms-flex-preferred-size: calc(50% - 15px);
          flex-basis: calc(50% - 15px);
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1; }
    .uik-social-about__wrapper .uik-social-about__listWrapper > *:nth-last-child(-n+2) {
      border-bottom: none; } }
.uik-social-about-item__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
.uik-widget__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 15px; }
  .uik-widget__container > *:not(:last-child) {
    margin-bottom: 30px; }
.uik-widget-bottom-cta__wrapper {
  height: 50px;
  padding: 0 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #9EA0A5;
  text-transform: uppercase;
  font-size: .75rem;
  font-weight: 500;
  border-top: 1px solid #EAEDF3; }
  .uik-widget-bottom-cta__wrapper:hover {
    color: #3E3F42; }
.uik-widget-link__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  padding: 0 30px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .uik-widget-link__wrapper .uik-widget-link__icon + * {
    margin-left: 20px;
    -ms-flex-negative: 0;
        flex-shrink: 0; }
  .uik-widget-link__wrapper .uik-widget-link__title {
    color: #3E3F42;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    overflow: hidden;
    white-space: nowrap; }
  .uik-widget-link__wrapper .uik-widget-link__count {
    color: #9EA0A5;
    margin-left: 20px;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    font-size: .875rem; }
  .uik-widget-link__wrapper:not(:last-child) {
    border-bottom: 1px solid #EAEDF3; }
  .uik-widget-link__wrapper:hover .uik-widget-link__title {
    color: #1665D8; }
.uik-widget-video-preview__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  padding: 0 30px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .uik-widget-video-preview__wrapper .uik-widget-video-preview__previewImage {
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    width: 74px;
    height: 56px;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover; }
  .uik-widget-video-preview__wrapper .uik-widget-video-preview__previewImage + * {
    margin-left: 20px;
    -ms-flex-negative: 0;
        flex-shrink: 0; }
  .uik-widget-video-preview__wrapper .uik-widget-video-preview__title {
    color: #3E3F42;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 500;
    color: #3E3F42;
    line-height: 1.5rem; }
  .uik-widget-video-preview__wrapper .uik-widget-video-preview__views {
    color: #9EA0A5;
    font-size: .875rem;
    line-height: 1.375rem;
    display: block;
    margin-top: 2px; }
  .uik-widget-video-preview__wrapper:not(:last-child) {
    margin-bottom: 16px; }
  .uik-widget-video-preview__wrapper:hover .uik-widget-video-preview__previewImage {
    -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
            transform: scale(1.05); }
  .uik-widget-video-preview__wrapper:hover .uik-widget-video-preview__title {
    color: #1665D8; }
.uik-widget-video-preview-container__wrapper {
  padding: 30px 0; }
.uik-widget-content__wrapper {
  padding: 25px 30px; }
  .uik-widget-content__wrapper + .uik-widget-content__wrapper {
    border-top: 1px solid #EAEDF3; }
  .uik-widget-content__wrapper.uik-widget-content__grey {
    background-color: #FBFBFD; }
.uik-widget-doc__wrapper h1:not([class]), .uik-widget-doc__wrapper h2:not([class]), .uik-widget-doc__wrapper h3:not([class]), .uik-widget-doc__wrapper h4:not([class]) {
  color: #3E3F42;
  max-width: 760px; }
  .uik-widget-doc__wrapper h1:not([class]) + p, .uik-widget-doc__wrapper h2:not([class]) + p, .uik-widget-doc__wrapper h3:not([class]) + p, .uik-widget-doc__wrapper h4:not([class]) + p {
    margin-top: 10px; }

.uik-widget-doc__wrapper p:not([class]) {
  color: #6B6C6F;
  max-width: 760px; }
  .uik-widget-doc__wrapper p:not([class]) + p {
    margin-top: .8rem; }

.uik-widget-doc__wrapper h1:not([class]) {
  /* Headline: */
  font-weight: 300;
  font-size: 2.25rem;
  line-height: 3.125rem; }

.uik-widget-doc__wrapper h2:not([class]) {
  /* Headline: */
  font-weight: 300;
  font-size: 1.875rem;
  line-height: 2.75rem;
  /* Headline: */ }

.uik-widget-doc__wrapper h3:not([class]) {
  font-size: 1.375rem;
  line-height: 2rem; }

.uik-widget-doc__wrapper ul {
  padding-left: 20px; }

.uik-widget-doc__wrapper a:not([class]) {
  color: #1665D8; }

.uik-widget-doc__wrapper p a:not([class]):hover {
  text-decoration: underline; }

.uik-widget-doc__wrapper em {
  color: #9EA0A5; }
.uik-container-v__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }
  .uik-container-v__container > * {
    min-height: 1px; }
.uik-container-h__wrapper {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .uik-container-h__wrapper > * {
    min-width: 1px; }
.uik-content__wrapper {
  padding: 15px; }
  .uik-content__wrapper.uik-content__contentCenter {
    max-width: 1170px;
    margin: 0 auto; }

.uik-content__item {
  margin: 15px; }
.uik-widget-table__wrapper {
  width: 100%;
  border-collapse: collapse; }
  .uik-widget-table__wrapper tr td,
  .uik-widget-table__wrapper tr th {
    text-align: left;
    padding: 15px 15px;
    border-bottom: 1px solid #EAEDF3;
    line-height: 1.5; }
    .uik-widget-table__wrapper tr td:first-child,
    .uik-widget-table__wrapper tr th:first-child {
      padding-left: 30px; }
    .uik-widget-table__wrapper tr td:last-child,
    .uik-widget-table__wrapper tr th:last-child {
      padding-right: 30px; }
  .uik-widget-table__wrapper tr th {
    font-weight: 500;
    font-size: .75rem;
    padding: 8px 15px;
    text-transform: uppercase;
    color: #9EA0A5;
    line-height: 1.125rem;
    height: 44px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .uik-widget-table__wrapper tr td {
    font-size: .875rem;
    height: 70px;
    padding: 0 inherit;
    color: #3E3F42; }
.uik-content__wrapper {
  padding: 15px; }
  .uik-content__wrapper.uik-content__contentCenter {
    max-width: 1170px;
    margin: 0 auto; }

.uik-content__item {
  margin: 15px; }
.uik-layout-main__wrapper {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }

.uik-layout-main__wrapperInner {
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .uik-layout-main__wrapperInner.uik-layout-main__contentCenter, .uik-layout-main__wrapperInner.uik-layout-main__contentCenterMedium {
    max-width: 1170px;
    margin: 0 auto; }
  .uik-layout-main__wrapperInner.uik-layout-main__contentCenter, .uik-layout-main__wrapperInner.uik-layout-main__contentCenterMedium {
    margin: 0 auto; }
  .uik-layout-main__wrapperInner.uik-layout-main__contentCenterMedium {
    max-width: 885px; }
  .uik-layout-main__wrapperInner.uik-layout-main__contentLeft {
    max-width: 1170px; }
.uik-scroll__wrapper {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }
.uik-nav-panel__wrapper {
  /* Menu Base: */
  background: white;
  border-right: 1px solid #EAEDF3;
  height: 100%;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-preferred-size: 270px;
      flex-basis: 270px;
  width: 270px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }
  .uik-nav-panel__wrapper.uik-nav-panel__positionRight {
    border-right: none;
    border-left: 1px solid #EAEDF3; }
