@charset "UTF-8";
@font-face {
  font-family: 'uikon';
  src: url("./fonts/uikon.eot?65atsz");
  src: url("./fonts/uikon.eot?65atsz#iefix") format("embedded-opentype"), url("./fonts/uikon.ttf?65atsz") format("truetype"), url("./fonts/uikon.woff?65atsz") format("woff"), url("./fonts/uikon.svg?65atsz#uikon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="uikon"],
[class*="uikon"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'uikon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.uikon-dropdown_arrow:before {
  content: ""; }

.uikon-arrow_left:before {
  content: ""; }

.uikon-arrow_right:before {
  content: ""; }

.uikon-plus_rounded:before {
  content: ""; }

.uikon-pin_rounded_circle:before {
  content: ""; }

.uikon-message_2:before {
  content: ""; }

.uikon-calendar_2:before {
  content: ""; }

.uikon-building_plan:before {
  content: "";
  color: #9ea0a5; }

.uikon-tap_click_force_touch:before {
  content: ""; }

.uikon-ruler:before {
  content: ""; }

.uikon-video_camera:before {
  content: ""; }

.uikon-phone_two:before {
  content: ""; }

.uikon-smile:before {
  content: ""; }

.uikon-attachment:before {
  content: ""; }

.uikon-picture:before {
  content: ""; }

.uikon-view_simple:before {
  content: ""; }

.uikon-lightbulb:before {
  content: ""; }

.uikon-wallet:before {
  content: ""; }

.uikon-view_grid:before {
  content: ""; }

.uikon-view_list:before {
  content: ""; }

.uikon-profile_card:before {
  content: ""; }

.uikon-front_store:before {
  content: ""; }

.uikon-rocket:before {
  content: ""; }

.uikon-buildings:before {
  content: ""; }

.uikon-building:before {
  content: ""; }

.uikon-shopping_bag:before {
  content: ""; }

.uikon-arrow-dropdown:before {
  content: ""; }

.uikon-check:before {
  content: ""; }

.uikon-more:before {
  content: ""; }

.uikon-trending_down:before {
  content: ""; }

.uikon-trending_up:before {
  content: ""; }

.uikon-star:before {
  content: ""; }

.uikon-star-filled:before {
  content: ""; }

.uikon-add:before {
  content: ""; }

.uikon-help:before {
  content: ""; }

.uikon-message:before {
  content: ""; }

.uikon-send_round:before {
  content: ""; }

.uikon-inbox_paper_round:before {
  content: ""; }

.uikon-search_left:before {
  content: ""; }

.uikon-settings:before {
  content: ""; }

.uikon-love:before {
  content: ""; }

.uikon-love-fill:before {
  content: ""; }

.uikon-edit:before {
  content: ""; }

.uikon-multitasking:before {
  content: ""; }

.uikon-gallery_grid_view:before {
  content: ""; }

.uikon-home:before {
  content: ""; }

.uikon-profile_round:before {
  content: ""; }

.uikon-profile_plus_round:before {
  content: ""; }

.uikon-clock:before {
  content: ""; }

.uikon-container:before {
  content: ""; }

.uikon-globe_network:before {
  content: ""; }

.uikon-calendar:before {
  content: ""; }

.uikon-stats:before {
  content: ""; }

.uikon-money_round:before {
  content: ""; }

.uikon-cloud_up:before {
  content: ""; }

.uikon-image_picture:before {
  content: ""; }

.uikon-camera:before {
  content: ""; }

.uikon-desktop:before {
  content: ""; }

.uikon-phone:before {
  content: ""; }

::-webkit-scrollbar-button {
  display: none;
  height: 13px;
  border-radius: 0px;
  background-color: orange; }

::-webkit-scrollbar-button:hover {
  background-color: orange; }

::-webkit-scrollbar-thumb {
  background: rgba(128, 128, 128, 0.2);
  border-radius: 7px;
  margin-right: 2px; }

::-webkit-scrollbar-thumb:hover {
  background: rgba(128, 128, 128, 0.4); }

::-webkit-scrollbar-track {
  display: none; }

::-webkit-scrollbar {
  width: 10px;
  padding: 1px;
  border-left: 1px solid rgba(128, 128, 128, 0.1); }

::-webkit-scrollbar {
  display: none; }

*,
::after,
::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0; }

html {
  height: 100%;
  background: #FBFBFD; }

:global .hidden {
  opacity: 0 !important;
  visibility: hidden; }

@media screen and (max-width: 768px) {
  :global .hidden-mobile {
    display: none; } }

body {
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  font-family: "Roboto", sans-serif;
  background: #FBFBFD;
  color: #6B6C6F;
  font-size: 16px;
  overflow: hidden;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  body input,
  body button,
  body textarea {
    color: #3E3F42;
    font-size: 16px;
    font-family: "Roboto", sans-serif; }
  body h1,
  body h2,
  body h3,
  body h4,
  body h5,
  body h6 {
    transition: all 0.2s;
    margin: 0;
    line-height: 1.5;
    font-weight: 400;
    color: #3E3F42; }
  body * + h1,
  body * + h2,
  body * + h3,
  body * + h4,
  body * + h5 {
    margin-top: 1.125rem; }
  body h1 + *,
  body h2 + *,
  body h3 + *,
  body h4 + * {
    margin-top: 1em; }
  body p {
    transition: all 0.2s;
    margin: 0;
    line-height: 1.375rem;
    font-weight: 400;
    font-size: 0.875rem;
    color: #9EA0A5; }
  body input,
  body textarea {
    font-size: .875rem; }
  body * + p {
    margin-top: 0.3em; }
  body h1 {
    font-size: 2.25rem;
    font-weight: 600; }
  body h2 {
    font-size: 1.375rem;
    line-height: 2rem; }
  body h3 {
    font-size: 1.125rem; }
  body h4 {
    font-size: 1.1rem; }
  body p + *,
  body ul + *,
  body ol + * {
    margin-top: 1.125em; }
  body p + p {
    margin-top: 0.4em; }
  body a {
    transition: .2s;
    text-decoration: none;
    cursor: pointer; }
  body p a,
  body ul a {
    display: inline-block;
    position: relative;
    transition: all 0.2s;
    color: #1665D8; }
    body p a:hover,
    body ul a:hover {
      text-decoration: underline; }
  body strong {
    font-weight: 500; }
  body ul,
  body ol {
    line-height: 1.5;
    color: #3E3F42;
    font-size: 0.875rem; }
    body ul *:not(:last-child),
    body ol *:not(:last-child) {
      margin-bottom: 8px; }
  body ol {
    padding-left: 20px; }
  body ul:global(.no-style) {
    list-style: none;
    padding-left: 0; }
    body ul:global(.no-style) ul {
      padding-left: 20px; }
  body code:not([class]) {
    display: inline-block;
    background: #FBFBFD;
    border: 1px solid #DADEE7;
    border-radius: 4px;
    font-weight: 500;
    font-size: 0.875rem;
    color: #34AA44;
    line-height: 1.375rem;
    padding: 4px 12px;
    font-family: "Roboto", sans-serif; }

:global #root {
  height: 100%; }

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #9EA0A5;
  opacity: 1;
  /* Firefox */ }

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #9EA0A5; }

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #9EA0A5; }

form {
  max-width: 760px; }

:global(.color-green) {
  color: #38B249; }

:global(.color-blue) {
  color: #1665D8; }

:global(.color-orange) {
  color: #F6AB2F; }

:global(.color-violet) {
  color: #E69F00; }

:global(.color-yellow) {
  color: #FACF55; }

:global(.color-red) {
  color: #E6492D; }
